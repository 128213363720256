import React, {createRef, useState} from "react"
import ReCAPTCHA from "react-google-recaptcha"
import {graphql, Link, useStaticQuery} from "gatsby"
import $ from "jquery"
import emailjs from "emailjs-com";
import { SERVICE_ID,USER_ID,TEMPLATE_ID, RECAPTCHA_KEY } from "./GLOBAL";

const ContactForm = props => {

    let q = useStaticQuery(graphql`
          query {
           allWordpressWpComplianceServices {
                edges {
                  node {
                    name
                    link
                    wordpress_id
                  }
                }
           }
           allWordpressWpLicensesAndApprovals {
                edges {
                  node {
                    name
                    link
                    wordpress_id
                  }
                }
           }
           allWordpressCategory {
                edges {
                  node {
                    name
                    link
                    wordpress_id
                    parent_element {
                      name
                    }
                  }
                }
           }
          }`);

    const myForm = React.useRef();
    const recaptchaRef = createRef();

    const [error, setError] = useState('d-none');
    const [cap, setCap] = useState(false);
    const [loading, setLoading] = useState('d-none');

     const onChangeCaptcha = value =>
     {
         setError('d-none');
         setCap(true);
     }


     const handleSubmit = event => {
         setLoading('d-inline-block');
         recaptchaRef.current.reset()

         event.preventDefault();

         if(!cap)
         {
             setError('d-block');
             setLoading('d-none');
             return false
         }

         if (props.modalId !== undefined && props.modalId  !== '' && props.modalId  !== null){
             setTimeout(function (){$("#"+props.modalId).modal('hide');}, 1500)
         }


         emailjs.init(USER_ID)
         emailjs
             .send(SERVICE_ID, TEMPLATE_ID, {
                 from_name: myForm.current.email.value,
                 my_html: '<ul>\n' +
                     '    <li><strong>From: </strong>'+myForm.current.name.value+'</li>\n' +
                     '    <li><strong>Email Id: </strong>'+myForm.current.email.value+'</li>\n' +
                     '    <li><strong>Phone No.: </strong>'+myForm.current.phone.value+'</li>\n' +
                     '    <li><strong>Company Name: </strong>'+myForm.current.company_name.value+'</li>\n' +
                     '    <li><strong>Category Of Product: </strong>'+myForm.current.category_of_product.value+'</li>\n' +
                     '    <li><strong>Service Required: </strong>'+myForm.current.service_required.value+'</li>\n' +
                     '    <li><strong>License Required: </strong>'+myForm.current.license_required.value+'</li>\n' +
                     '    <li><strong>Comment: </strong><br/>'+myForm.current.other.value+'</li>\n' +
                     '</ul>',
             })
             .then(
                 function(response) {

                     let m = $("#formReponseModel");
                     m.modal('show');
                     m.find(".success-class").show()
                     m.find(".error-class").hide()
                     setLoading('d-none');
                     myForm.current.reset()
                     setCap(false);
                     setTimeout(function (){m.modal('hide');}, 5000)
                 },
                 function(error) {

                     let m = $("#formReponseModel");
                     m.modal('show');
                     m.find(".error-class").show()
                     m.find(".success-class").hide()
                     m.find(".error-class").text("Error: "+error.text)
                     setLoading('d-none');
                     setCap(false);
                     setTimeout(function (){m.modal('hide');}, 5000)
                 }
             )
     }

    return (
        <form className="p-3 p-md-5" action="!#" method="POST" ref={myForm} onSubmit={handleSubmit}>

            <div className="form-row">

                <div className="form-group col-md-6">
                    <label>Name*</label>
                    <input type="text" className="form-control" name="name"  required/>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="inputEmail4">Email*</label>
                    <input type="email" className="form-control" name="email" id="inputEmail4"
                           placeholder="eg. contact@gmail.com" required/>
                </div>
            </div>

            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="inputphone">Phone No. / Mobile No.*</label>
                    <input type="text" className="form-control" name="phone" id="inputphone" placeholder="+91-XXXXXXXXXX" required/>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="inputCompany">Company Name</label>
                    <input type="text" className="form-control" name="company_name" id="inputCompany"/>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-12">
                    <label htmlFor="input_category_of_product">Category Of Product</label>
                    <select id="input_category_of_product" name="category_of_product" className="form-control"
                            defaultValue={'N/A'}>
                        <option value="N/A">Select</option>
                        {
                            q.allWordpressCategory.edges.map((node, i) => (
                                <option value={node.node.name} key={i} dangerouslySetInnerHTML={{__html: node.node.name}} />
                            ))
                        }

                    </select>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-lg-6">
                    <label htmlFor="input_service_required">Service Required</label>
                    <select id="input_service_required" name="service_required" className="form-control" defaultValue={'N/A'}>
                        <option value="N/A">Select</option>
                        {
                            q.allWordpressWpComplianceServices.edges.map((node, i) => (
                                <option value={node.node.name} key={i} dangerouslySetInnerHTML={{__html: node.node.name}} />
                            ))
                        }
                    </select>
                </div>
                <div className="form-group col-lg-6">
                    <label htmlFor="input_license_required">License Required</label>
                    <select id="input_license_required" name="license_required" className="form-control" defaultValue={'N/A'}>
                        <option value="N/A">Select</option>
                        {
                            q.allWordpressWpLicensesAndApprovals.edges.map((node, i) => (
                                <option value={node.node.name} key={i} dangerouslySetInnerHTML={{__html: node.node.name}} />
                            ))
                        }
                    </select>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-12">
                    <label htmlFor="inputComment">Comment</label>
                    <textarea className="form-control" name="other" id="inputComment" placeholder="Comment." rows="4" cols="3"> </textarea>
                </div>
            </div>

            <div className="form-row">
                <div className="form-group col-md-6">
                    <ReCAPTCHA sitekey={RECAPTCHA_KEY} ref={recaptchaRef} onChange={onChangeCaptcha} />
                </div>
                <div className="form-group col-md-6 text-right">
                    <button type="submit"
                            className="btn btn-outline-light rounded-pill pl-4 theme-bg-blue pr-4 fsm-theme-small-text ml-auto">SUBMIT

                        <div className={"spinner-border ml-2 "+loading} role="status" style={{height: '20px',width: '20px'}}>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </button>
                </div>
            </div>
            <div className={"form-row "+error}>
                <div className="form-group col-md-12">
                    <p className="fsm-theme-small-text text-danger">Error : &nbsp; Please complete captcha first</p>
                </div>
            </div>


        </form>
    )
}
export default ContactForm
